<template>
  <div>
    <ValidationObserver @submit="addPaymentOption">
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="showModal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <span>Add New Option</span>
        </template>
        <template v-slot>
          <div>
            <div class="flex flex-col">
              <UiSingleSelect
                v-model="paymentOptionData.title"
                title="Methods"
                :options="paymentMethods"
                class="flex-1"
                reduce="short"
                rules="required"
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex gap-3 rtl:gap-x-4">
            <UIButton @click="handleClick('close')">Cancel</UIButton>
            <UIButton button="primary">
              <span v-if="isLoading">
                <Loader />
              </span>
              <span v-else>Add</span>
            </UIButton>
          </div>
        </template>
      </UiModalContainer>
    </ValidationObserver>
  </div>
</template>

<script>
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { Form as ValidationObserver } from 'vee-validate'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    ValidationObserver,
    UIButton,
    Loader,
  },
  emits: ['updateData'],
  data() {
    return {
      paymentMethods: [{ title: 'Stripe', short: 'stripe' }],
      paymentOptionData: {
        title: '',
      },
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      isLoading: (state) => state.layout.isLoading,
    }),
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowModal(true)
          break
        case 'close':
          this.setShowModal(false)
          break
      }
    },

    async addPaymentOption() {
      await this.createStripeStandardAccount()
      this.$emit('updateData')
      this.handleClick('close')
    },
    ...mapActions('layout', ['setShowModal']),
    ...mapActions('paymentMethods', ['createStripeStandardAccount']),
  },
}
</script>
