<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="PAYMENT_METHODS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div>
      <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
      <div v-else-if="!isLoading && isEmpty(paymentMethodsList)">
        <NoRecordFound />
      </div>
      <div v-else>
        <div class="mt-5 relative rounded-lg border border-primary-grey">
          <div
            id="table-container"
            :class="[leftBlurArea, rightBlurArea]"
            class="overflow-x-auto"
            @scroll.passive="scrollDetect"
          >
            <table class="w-full table-auto whitespace-nowrap">
              <thead class="bg-bright-gray">
                <tr class="text-left">
                  <th
                    v-for="head in tableHeaders"
                    :key="head"
                    class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 ltr:pl-4 rtl:pl-0 ltr:pr-0"
                  >
                    <span
                      class="ltr:border-r rtl:border-l border-primary-grey flex items-center gap-1"
                    >
                      <span>{{ head }}</span>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(option, index) in paymentMethodsList"
                  :key="index"
                  class="border-t border-primary-grey text-left"
                >
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                  >
                    <span class="font-roboto font-normal text-text-color text-sm capitalize">
                      {{ option.type }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                  >
                    <span class="font-roboto font-normal text-text-color text-sm capitalize">
                      {{ option.account_id }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                  >
                    <span class="font-roboto font-normal text-text-color text-sm capitalize">
                      {{ option.country }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 uppercase rtl:text-right rtl:pr-4 ltr:px-4"
                  >
                    {{ option.default_currency }}
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                  >
                    {{ option.onboarding_completed }}
                  </td>
                  <td class="border-primary-grey w-10 px-5">
                    <TableAction
                      :idx="index"
                      :current-user="option"
                      :action-list="actionList"
                      :right="50"
                      :rtl-right="-245"
                      @action="typeAction"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AddPaymentMethodModal @updateData="getNewData" />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import Loader from '@components/BaseComponent/Loader.vue'
import AddPaymentMethodModal from '@/src/components/payment-methods/AddPaymentMethodModal.vue'
import { URL_SCHEME } from '@src/services/.env'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
export default {
  components: {
    TitleWrapper,
    TableAction,
    Loader,
    AddPaymentMethodModal,
    NoRecordFound,
  },
  mixins: [scrollMixin],
  data() {
    return {
      dashboard: 'dashboard',
      paymentMethodsList: [],
      tableHeaders: ['Title', 'Account Id', 'Country', 'Default Currency', 'Onboarded', 'Actions'],
      actionList: [{ name: 'Deauthorize' }, { name: 'Onboard New Account' }],
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      isLoading: (state) => state.paymentMethods.isLoading,
    }),

    ...mapState('layout', ['currentCampusScope', 'currentSectionScope']),
  },
  created() {
    this.setRightbarData()
    this.getNewData()
  },
  methods: {
    async getNewData() {
      const [res, err] = await this.getAllAccounts()
      this.paymentMethodsList = res.data.records
    },

    typeAction(action, currentType, idx) {
      switch (action) {
        case 'Onboard New Account':
          this.linkAnAccount()
          break
        case 'Deauthorize':
          this.deauthorizeStripe()
          break
      }
    },

    async linkAnAccount() {
      const basePath = location.host
      const routePath = this.$route.fullPath
      const data = {
        return_url: `${URL_SCHEME}://${basePath}${routePath}`,
        refresh_url: `${URL_SCHEME}://${basePath}${routePath}`,
        type: 'account_onboarding',
      }
      const [res, err] = await this.onBoardUser(data)
      if (res) {
        window.location.href = res.data.url
      }
    },

    async deauthorizeStripe() {
      await this.deAuthorize()
      this.getNewData()
    },

    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'PAYMENT_METHODS',
          buttons: [
            {
              title: 'Add Payment Option',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'Total Types', value: length }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('paymentMethods', ['getAllAccounts', 'onBoardUser', 'deAuthorize']),
  },
}
</script>
